export const links = [
  {
    id: 1,
    text: "Mock Draft Simulator",
    url: "/select-draft",
  },
  {
    id: 2,
    text: "Draft Value Chart",
    url: "/draft-value-chart",
  },
  {
    id: 3,
    text: "Players",
    url: "/players",
  },
  {
    id: 4,
    text: "Team Needs",
    url: "/team-needs",
  },
  // {
  //   id: 5,
  //   text: "Podcaster / Premium",
  //   url: "/podcaster",
  // },
];