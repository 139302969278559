import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  loading: false,
  error: null,
  message: "",
  resultBoard: [
    {
      id: 3882,
      pick: 1,
      index: 1,
      tm: "Chicago Bears",
      created_at: "2023-10-30T13:13:43.858701Z",
      updated_at: "2023-10-30T13:13:43.858777Z",
      round_index_number: "1",
      round_index: "round 1",
      switching: "nan",
      city: "Chicago",
      value: "1000.0",
      round: {
        id: 8,
        name: "Bears",
        adp: 1,
        index: 8,
        logo: "https://api.nfldraftfanatics.com/media/chi.png",
      },
      player: {
        id: 3601,
        index: 1,
        name: "Caleb Williams",
        team_name: "USC",
        school: "USC",
        position: "QB",
        value: 200,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3883,
      pick: 2,
      index: 2,
      tm: "Arizona Cardinals",
      created_at: "2023-10-30T13:13:43.889469Z",
      updated_at: "2023-10-30T13:13:43.889531Z",
      round_index_number: "1",
      round_index: "round 1",
      switching: "nan",
      city: "Arizona",
      value: "717.0",
      round: {
        id: 20,
        name: "Cardinals",
        adp: null,
        index: 21,
        logo: "https://api.nfldraftfanatics.com/media/ari.png",
      },
      player: {
        id: 3602,
        index: 2,
        name: "Marvin Harrison Jr.",
        team_name: "Ohio State",
        school: "Ohio State",
        position: "WR",
        value: 199,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3884,
      pick: 3,
      index: 3,
      tm: "New England Patriots",
      created_at: "2023-10-30T13:13:43.899142Z",
      updated_at: "2023-11-26T19:20:08.164562Z",
      round_index_number: "1",
      round_index: "round 1",
      switching: "nan",
      city: "New England",
      value: "514.0",
      round: {
        id: 15,
        name: "Patriots",
        adp: null,
        index: 16,
        logo: "https://api.nfldraftfanatics.com/media/ne.png",
      },
      player: {
        id: 3603,
        index: 3,
        name: "Drake Maye",
        team_name: "North Carolina",
        school: "North Carolina",
        position: "QB",
        value: 198,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3885,
      pick: 4,
      index: 4,
      tm: "Chicago Bears",
      created_at: "2023-10-30T13:13:43.907355Z",
      updated_at: "2023-11-26T19:20:43.735166Z",
      round_index_number: "1",
      round_index: "round 1",
      switching: "nan",
      city: "Chicago",
      value: "491.0",
      round: {
        id: 8,
        name: "Bears",
        adp: 1,
        index: 8,
        logo: "https://api.nfldraftfanatics.com/media/chi.png",
      },
      player: {
        id: 3604,
        index: 4,
        name: "Joe Alt",
        team_name: "Notre Dame",
        school: "Notre Dame",
        position: "OT",
        value: 197,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3886,
      pick: 5,
      index: 5,
      tm: "New York Giants",
      created_at: "2023-10-30T13:13:43.915991Z",
      updated_at: "2023-11-26T19:21:14.211402Z",
      round_index_number: "1",
      round_index: "round 1",
      switching: "nan",
      city: "New York",
      value: "468.0",
      round: {
        id: 9,
        name: "Giants",
        adp: null,
        index: 9,
        logo: "https://api.nfldraftfanatics.com/media/nyg.png",
      },
      player: {
        id: 3605,
        index: 5,
        name: "Olumuyiwa Fashanu",
        team_name: "Penn State",
        school: "Penn State",
        position: "OT",
        value: 196,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3887,
      pick: 6,
      index: 6,
      tm: "Tennessee Titans",
      created_at: "2023-10-30T13:13:43.923836Z",
      updated_at: "2023-11-26T19:21:36.961702Z",
      round_index_number: "1",
      round_index: "round 1",
      switching: "nan",
      city: "Tennessee",
      value: "446.0",
      round: {
        id: 16,
        name: "Titans",
        adp: null,
        index: 17,
        logo: "https://api.nfldraftfanatics.com/media/ten.png",
      },
      player: {
        id: 3606,
        index: 6,
        name: "Brock Bowers",
        team_name: "Georgia",
        school: "Georgia",
        position: "TE",
        value: 195,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3888,
      pick: 7,
      index: 7,
      tm: "Washington Commanders",
      created_at: "2023-10-30T13:13:43.932839Z",
      updated_at: "2023-11-26T19:22:12.522300Z",
      round_index_number: "1",
      round_index: "round 1",
      switching: "nan",
      city: "Washington",
      value: "426.0",
      round: {
        id: 32,
        name: "Commanders",
        adp: null,
        index: 11,
        logo: "https://api.nfldraftfanatics.com/media/wsh_5TI9IsB.png",
      },
      player: {
        id: 3607,
        index: 7,
        name: "Jared Verse",
        team_name: "Florida State",
        school: "Florida State",
        position: "EDGE",
        value: 194,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3889,
      pick: 8,
      index: 8,
      tm: "New York Jets",
      created_at: "2023-10-30T13:13:43.942905Z",
      updated_at: "2023-11-26T19:22:34.566891Z",
      round_index_number: "1",
      round_index: "round 1",
      switching: "nan",
      city: "New York",
      value: "406.0",
      round: {
        id: 5,
        name: "Jets",
        adp: null,
        index: 5,
        logo: "https://api.nfldraftfanatics.com/media/nyj.png",
      },
      player: {
        id: 3608,
        index: 8,
        name: "Jer'Zhan Newton",
        team_name: "Illinois",
        school: "Illinois",
        position: "DL",
        value: 193,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3890,
      pick: 9,
      index: 9,
      tm: "Atlanta Falcons",
      created_at: "2023-10-30T13:13:43.953819Z",
      updated_at: "2023-11-26T19:23:10.205673Z",
      round_index_number: "1",
      round_index: "round 1",
      switching: "nan",
      city: "Atlanta",
      value: "387.0",
      round: {
        id: 1,
        name: "Falcons",
        adp: null,
        index: 1,
        logo: "https://api.nfldraftfanatics.com/media/atl_1.png",
      },
      player: {
        id: 3609,
        index: 9,
        name: "Kool-Aid McKinstry",
        team_name: "Alabama",
        school: "Alabama",
        position: "CB",
        value: 192,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3891,
      pick: 10,
      index: 10,
      tm: "Tampa Bay Buccaneers",
      created_at: "2023-10-30T13:13:43.968109Z",
      updated_at: "2023-11-26T19:23:34.405887Z",
      round_index_number: "1",
      round_index: "round 1",
      switching: "nan",
      city: "Tampa Bay",
      value: "369.0",
      round: {
        id: 30,
        name: "Buccaneers",
        adp: 15,
        index: 31,
        logo: "https://api.nfldraftfanatics.com/media/tb.png",
      },
      player: {
        id: 3610,
        index: 10,
        name: "Laiatu Latu",
        team_name: "UCLA",
        school: "UCLA",
        position: "EDGE",
        value: 191,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3892,
      pick: 11,
      index: 11,
      tm: "Los_Angeles Chargers",
      created_at: "2023-10-30T13:13:43.976136Z",
      updated_at: "2023-11-26T19:24:02.039080Z",
      round_index_number: "1",
      round_index: "round 1",
      switching: "nan",
      city: "Los Angeles",
      value: "358.0",
      round: {
        id: 22,
        name: "Chargers",
        adp: null,
        index: 23,
        logo: "https://api.nfldraftfanatics.com/media/lac.png",
      },
      player: {
        id: 3611,
        index: 11,
        name: "Kalen King",
        team_name: "Penn State",
        school: "Penn State",
        position: "CB",
        value: 190,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3893,
      pick: 12,
      index: 12,
      tm: "Los Angeles Rams",
      created_at: "2023-10-30T13:13:43.984703Z",
      updated_at: "2023-11-26T19:24:35.056015Z",
      round_index_number: "1",
      round_index: "round 1",
      switching: "nan",
      city: "Los_Angeles",
      value: "347.0",
      round: {
        id: 27,
        name: "Rams",
        adp: null,
        index: 28,
        logo: "https://api.nfldraftfanatics.com/media/lar.png",
      },
      player: {
        id: 3612,
        index: 12,
        name: "Malik Nabers",
        team_name: "LSU",
        school: "LSU",
        position: "WR",
        value: 189,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3894,
      pick: 13,
      index: 13,
      tm: "Green Bay Packers",
      created_at: "2023-10-30T13:13:43.993295Z",
      updated_at: "2023-11-26T19:25:20.132031Z",
      round_index_number: "1",
      round_index: "round 1",
      switching: "nan",
      city: "Green Bay",
      value: "336.0",
      round: {
        id: 28,
        name: "Packers",
        adp: null,
        index: 29,
        logo: "https://api.nfldraftfanatics.com/media/gb_1.png",
      },
      player: {
        id: 3613,
        index: 13,
        name: "Leonard Taylor III",
        team_name: "Miami (FL)",
        school: "Miami (FL)",
        position: "DL",
        value: 188,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3895,
      pick: 14,
      index: 14,
      tm: "Las Vegas Raiders",
      created_at: "2023-10-30T13:13:44.003649Z",
      updated_at: "2023-11-26T19:26:21.419159Z",
      round_index_number: "1",
      round_index: "round 1",
      switching: "nan",
      city: "Las Vegas",
      value: "325.0",
      round: {
        id: 14,
        name: "Raiders",
        adp: null,
        index: 15,
        logo: "https://api.nfldraftfanatics.com/media/lv.png",
      },
      player: {
        id: 3614,
        index: 14,
        name: "Denzel Burke",
        team_name: "Ohio State",
        school: "Ohio State",
        position: "CB",
        value: 187,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3896,
      pick: 15,
      index: 15,
      tm: "Indianapolis Colts",
      created_at: "2023-10-30T13:13:44.021951Z",
      updated_at: "2023-11-26T19:27:03.614393Z",
      round_index_number: "1",
      round_index: "round 1",
      switching: "nan",
      city: "Indianapolis",
      value: "315.0",
      round: {
        id: 24,
        name: "Colts",
        adp: null,
        index: 25,
        logo: "https://api.nfldraftfanatics.com/media/ind.png",
      },
      player: {
        id: 3615,
        index: 15,
        name: "J.C. Latham",
        team_name: "Alabama",
        school: "Alabama",
        position: "OT",
        value: 186,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3897,
      pick: 16,
      index: 16,
      tm: "Denver Broncos",
      created_at: "2023-10-30T13:13:44.046101Z",
      updated_at: "2023-11-26T19:27:46.770760Z",
      round_index_number: "1",
      round_index: "round 1",
      switching: "nan",
      city: "Denver",
      value: "305.0",
      round: {
        id: 21,
        name: "Broncos",
        adp: null,
        index: 22,
        logo: "https://api.nfldraftfanatics.com/media/den.png",
      },
      player: {
        id: 3616,
        index: 16,
        name: "Rome Odunze",
        team_name: "Washington",
        school: "Washington",
        position: "WR",
        value: 185,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3898,
      pick: 17,
      index: 17,
      tm: "Cincinnati Bengals",
      created_at: "2023-10-30T13:13:44.057512Z",
      updated_at: "2023-10-30T13:13:44.057573Z",
      round_index_number: "1",
      round_index: "round 1",
      switching: "nan",
      city: "Cincinnati",
      value: "296.0",
      round: {
        id: 26,
        name: "Bengals",
        adp: null,
        index: 27,
        logo: "https://api.nfldraftfanatics.com/media/cin.png",
      },
      player: {
        id: 3617,
        index: 17,
        name: "Dallas Turner",
        team_name: "Alabama",
        school: "Alabama",
        position: "EDGE",
        value: 184,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3899,
      pick: 18,
      index: 18,
      tm: "Buffalo Bills",
      created_at: "2023-10-30T13:13:44.069280Z",
      updated_at: "2023-11-26T19:29:39.429718Z",
      round_index_number: "1",
      round_index: "round 1",
      switching: "nan",
      city: "Buffalo",
      value: "287.0",
      round: {
        id: 31,
        name: "Bills",
        adp: null,
        index: 32,
        logo: "https://api.nfldraftfanatics.com/media/buf.png",
      },
      player: {
        id: 3618,
        index: 18,
        name: "Kamren Kinchens",
        team_name: "Miami (FL)",
        school: "Miami (FL)",
        position: "S",
        value: 183,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3900,
      pick: 19,
      index: 19,
      tm: "New Orleans Saints",
      created_at: "2023-10-30T13:13:44.077521Z",
      updated_at: "2023-11-26T19:30:14.448594Z",
      round_index_number: "1",
      round_index: "round 1",
      switching: "nan",
      city: "New Orleans",
      value: "278.0",
      round: {
        id: 13,
        name: "Saints",
        adp: null,
        index: 14,
        logo: "https://api.nfldraftfanatics.com/media/no.png",
      },
      player: {
        id: 3619,
        index: 19,
        name: "Emeka Egbuka",
        team_name: "Ohio State",
        school: "Ohio State",
        position: "WR",
        value: 182,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3901,
      pick: 20,
      index: 20,
      tm: "Minnesota Vikings",
      created_at: "2023-10-30T13:13:44.093224Z",
      updated_at: "2023-10-30T13:13:44.093280Z",
      round_index_number: "1",
      round_index: "round 1",
      switching: "nan",
      city: "Minnesota",
      value: "269.0",
      round: {
        id: 12,
        name: "Vikings",
        adp: null,
        index: 13,
        logo: "https://api.nfldraftfanatics.com/media/min.png",
      },
      player: {
        id: 3620,
        index: 20,
        name: "Ja'Tavion Sanders",
        team_name: "Texas",
        school: "Texas",
        position: "TE",
        value: 181,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3902,
      pick: 21,
      index: 21,
      tm: "Seattle Seahawks",
      created_at: "2023-10-30T13:13:44.106082Z",
      updated_at: "2023-11-26T19:31:06.254129Z",
      round_index_number: "1",
      round_index: "round 1",
      switching: "nan",
      city: "Seattle",
      value: "261.0",
      round: {
        id: 4,
        name: "Seahawks",
        adp: null,
        index: 4,
        logo: "https://api.nfldraftfanatics.com/media/sea.png",
      },
      player: {
        id: 3621,
        index: 21,
        name: "TreVeyon Henderson",
        team_name: "Ohio State",
        school: "Ohio State",
        position: "RB",
        value: 180,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3903,
      pick: 22,
      index: 22,
      tm: "Arizona Cardinals",
      created_at: "2023-10-30T13:13:44.116860Z",
      updated_at: "2023-11-26T19:33:05.397841Z",
      round_index_number: "1",
      round_index: "round 1",
      switching: "nan",
      city: "Arizona",
      value: "253.0",
      round: {
        id: 20,
        name: "Cardinals",
        adp: null,
        index: 21,
        logo: "https://api.nfldraftfanatics.com/media/ari.png",
      },
      player: {
        id: 3622,
        index: 22,
        name: "Jeremiah Trotter Jr.",
        team_name: "Clemson",
        school: "Clemson",
        position: "LB",
        value: 179,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3904,
      pick: 23,
      index: 23,
      tm: "Pittsburgh Steelers",
      created_at: "2023-10-30T13:13:44.129166Z",
      updated_at: "2023-11-26T19:32:29.748221Z",
      round_index_number: "1",
      round_index: "round 1",
      switching: "nan",
      city: "Pittsburgh",
      value: "245.0",
      round: {
        id: 11,
        name: "Steelers",
        adp: null,
        index: 12,
        logo: "https://api.nfldraftfanatics.com/media/pit.png",
      },
      player: {
        id: 3623,
        index: 23,
        name: "Shedeur Sanders",
        team_name: "Colorado",
        school: "Colorado",
        position: "QB",
        value: 178,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3905,
      pick: 24,
      index: 24,
      tm: "Houston Texans",
      created_at: "2023-10-30T13:13:44.137997Z",
      updated_at: "2023-11-26T19:33:40.370961Z",
      round_index_number: "1",
      round_index: "round 1",
      switching: "nan",
      city: "Houston",
      value: "237.0",
      round: {
        id: 2,
        name: "Texans",
        adp: null,
        index: 2,
        logo: "https://api.nfldraftfanatics.com/media/hou.png",
      },
      player: {
        id: 3624,
        index: 24,
        name: "Chop Robinson",
        team_name: "Penn State",
        school: "Penn State",
        position: "EDGE",
        value: 177,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3906,
      pick: 25,
      index: 25,
      tm: "Kansas City Chiefs",
      created_at: "2023-10-30T13:13:44.147763Z",
      updated_at: "2023-11-26T19:34:12.873881Z",
      round_index_number: "1",
      round_index: "round 1",
      switching: "nan",
      city: "Kansas City",
      value: "230.0",
      round: {
        id: 29,
        name: "Chiefs",
        adp: null,
        index: 30,
        logo: "https://api.nfldraftfanatics.com/media/kc.png",
      },
      player: {
        id: 3625,
        index: 25,
        name: "Cooper DeJean",
        team_name: "Iowa",
        school: "Iowa",
        position: "S",
        value: 176,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3907,
      pick: 26,
      index: 26,
      tm: "Jacksonville Jaguars",
      created_at: "2023-10-30T13:13:44.155824Z",
      updated_at: "2023-11-26T19:37:04.281935Z",
      round_index_number: "1",
      round_index: "round 1",
      switching: "nan",
      city: "Jacksonville",
      value: "223.0",
      round: {
        id: 6,
        name: "Jaguars",
        adp: null,
        index: 6,
        logo: "https://api.nfldraftfanatics.com/media/jax.png",
      },
      player: {
        id: 3626,
        index: 26,
        name: "J.T. Tuimoloau",
        team_name: "Ohio State",
        school: "Ohio State",
        position: "EDGE",
        value: 175,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3908,
      pick: 27,
      index: 27,
      tm: "Dallas Cowboys",
      created_at: "2023-10-30T13:13:44.165736Z",
      updated_at: "2023-11-26T19:38:58.382664Z",
      round_index_number: "1",
      round_index: "round 1",
      switching: "nan",
      city: "Dallas",
      value: "216.0",
      round: {
        id: 25,
        name: "Cowboys",
        adp: null,
        index: 26,
        logo: "https://api.nfldraftfanatics.com/media/dal.png",
      },
      player: {
        id: 3627,
        index: 27,
        name: "Calen Bullock",
        team_name: "USC",
        school: "USC",
        position: "S",
        value: 174,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3909,
      pick: 28,
      index: 28,
      tm: "Detroit Lions",
      created_at: "2023-10-30T13:13:44.181405Z",
      updated_at: "2023-11-26T19:39:28.167561Z",
      round_index_number: "1",
      round_index: "round 1",
      switching: "nan",
      city: "Detroit",
      value: "209.0",
      round: {
        id: 3,
        name: "Lions",
        adp: null,
        index: 3,
        logo: "https://api.nfldraftfanatics.com/media/det.png",
      },
      player: {
        id: 3628,
        index: 28,
        name: "Bralen Trice",
        team_name: "Washington",
        school: "Washington",
        position: "EDGE",
        value: 173,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3910,
      pick: 29,
      index: 29,
      tm: "San Francisco 49ers",
      created_at: "2023-10-30T13:13:44.192303Z",
      updated_at: "2023-11-26T19:40:44.177923Z",
      round_index_number: "1",
      round_index: "round 1",
      switching: "nan",
      city: "San Francisco",
      value: "202.0",
      round: {
        id: 23,
        name: "49ers",
        adp: null,
        index: 24,
        logo: "https://api.nfldraftfanatics.com/media/sf.png",
      },
      player: {
        id: 3629,
        index: 29,
        name: "Graham Barton",
        team_name: "Duke",
        school: "Duke",
        position: "OT",
        value: 172,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3911,
      pick: 30,
      index: 30,
      tm: "Baltimore Ravens",
      created_at: "2023-10-30T13:13:44.206874Z",
      updated_at: "2023-11-26T19:41:23.206113Z",
      round_index_number: "1",
      round_index: "round 1",
      switching: "nan",
      city: "Baltimore",
      value: "196.0",
      round: {
        id: 19,
        name: "Ravens",
        adp: null,
        index: 20,
        logo: "https://api.nfldraftfanatics.com/media/bal.png",
      },
      player: {
        id: 3630,
        index: 30,
        name: "Michael Hall Jr.",
        team_name: "Ohio State",
        school: "Ohio State",
        position: "DL",
        value: 171,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3912,
      pick: 31,
      index: 31,
      tm: "Miami Dolphins",
      created_at: "2023-10-30T13:13:44.230711Z",
      updated_at: "2023-11-26T19:41:50.380571Z",
      round_index_number: "1",
      round_index: "round 1",
      switching: "nan",
      city: "Miami",
      value: "190.0",
      round: {
        id: 33,
        name: "Dolphins",
        adp: 12,
        index: 10,
        logo: "https://api.nfldraftfanatics.com/media/MIA.png",
      },
      player: {
        id: 3631,
        index: 31,
        name: "Raheim Sanders",
        team_name: "Arkansas",
        school: "Arkansas",
        position: "RB",
        value: 170,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3913,
      pick: 32,
      index: 32,
      tm: "Philadelphia Eagles",
      created_at: "2023-10-30T13:13:44.239954Z",
      updated_at: "2023-10-30T13:13:44.240005Z",
      round_index_number: "1",
      round_index: "round 1",
      switching: "nan",
      city: "Philadelphia",
      value: "184.0",
      round: {
        id: 18,
        name: "Eagles",
        adp: null,
        index: 19,
        logo: "https://api.nfldraftfanatics.com/media/phi.png",
      },
      player: {
        id: 3632,
        index: 32,
        name: "Kamari Lassiter",
        team_name: "Georgia",
        school: "Georgia",
        position: "CB",
        value: 169,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3914,
      pick: 1,
      index: 33,
      tm: "Arizona Cardinals",
      created_at: "2023-10-30T13:13:44.256012Z",
      updated_at: "2023-10-30T13:13:44.256073Z",
      round_index_number: "2",
      round_index: "round 2",
      switching: "nan",
      city: "Arizona",
      value: "180.0",
      round: {
        id: 20,
        name: "Cardinals",
        adp: null,
        index: 21,
        logo: "https://api.nfldraftfanatics.com/media/ari.png",
      },
      player: {
        id: 3633,
        index: 33,
        name: "Xavier Worthy",
        team_name: "Texas",
        school: "Texas",
        position: "WR",
        value: 168,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3915,
      pick: 2,
      index: 34,
      tm: "Carolina Panthers",
      created_at: "2023-10-30T13:13:44.265609Z",
      updated_at: "2023-10-30T13:13:44.265662Z",
      round_index_number: "2",
      round_index: "round 2",
      switching: "nan",
      city: "Carolina",
      value: "175.0",
      round: {
        id: 7,
        name: "Panthers",
        adp: null,
        index: 7,
        logo: "https://api.nfldraftfanatics.com/media/car.png",
      },
      player: {
        id: 3634,
        index: 34,
        name: "Nate Wiggins",
        team_name: "Clemson",
        school: "Clemson",
        position: "CB",
        value: 167,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3916,
      pick: 3,
      index: 35,
      tm: "New_England Patriots",
      created_at: "2023-10-30T13:13:44.274975Z",
      updated_at: "2023-10-30T13:13:44.275036Z",
      round_index_number: "2",
      round_index: "round 2",
      switching: "nan",
      city: "New_England",
      value: "170.0",
      round: {
        id: 15,
        name: "Patriots",
        adp: null,
        index: 16,
        logo: "https://api.nfldraftfanatics.com/media/ne.png",
      },
      player: {
        id: 3635,
        index: 35,
        name: "Amarius Mims",
        team_name: "Georgia",
        school: "Georgia",
        position: "OT",
        value: 166,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3917,
      pick: 4,
      index: 36,
      tm: "New_York Giants",
      created_at: "2023-10-30T13:13:44.289633Z",
      updated_at: "2023-10-30T13:13:44.289689Z",
      round_index_number: "2",
      round_index: "round 2",
      switching: "nan",
      city: "New_York",
      value: "166.0",
      round: {
        id: 9,
        name: "Giants",
        adp: null,
        index: 9,
        logo: "https://api.nfldraftfanatics.com/media/nyg.png",
      },
      player: {
        id: 3636,
        index: 36,
        name: "Quinn Ewers",
        team_name: "Texas",
        school: "Texas",
        position: "QB",
        value: 165,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3918,
      pick: 5,
      index: 37,
      tm: "Green_Bay Packers",
      created_at: "2023-10-30T13:13:44.299344Z",
      updated_at: "2023-10-30T13:13:44.299406Z",
      round_index_number: "2",
      round_index: "round 2",
      switching: "nan",
      city: "Green_Bay",
      value: "162.0",
      round: {
        id: 28,
        name: "Packers",
        adp: null,
        index: 29,
        logo: "https://api.nfldraftfanatics.com/media/gb_1.png",
      },
      player: {
        id: 3637,
        index: 37,
        name: "Maason Smith",
        team_name: "LSU",
        school: "LSU",
        position: "DL",
        value: 164,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3919,
      pick: 6,
      index: 38,
      tm: "New_Orleans Saints",
      created_at: "2023-10-30T13:13:44.310787Z",
      updated_at: "2023-10-30T13:13:44.310849Z",
      round_index_number: "2",
      round_index: "round 2",
      switching: "nan",
      city: "New_Orleans",
      value: "157.0",
      round: {
        id: 13,
        name: "Saints",
        adp: null,
        index: 14,
        logo: "https://api.nfldraftfanatics.com/media/no.png",
      },
      player: {
        id: 3638,
        index: 38,
        name: "Cooper Beebe",
        team_name: "Kansas State",
        school: "Kansas State",
        position: "G",
        value: 163,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3920,
      pick: 7,
      index: 39,
      tm: "Washington Commanders",
      created_at: "2023-10-30T13:13:44.333397Z",
      updated_at: "2023-11-10T23:42:14.396389Z",
      round_index_number: "2",
      round_index: "round 2",
      switching: "nan",
      city: "Washington",
      value: "153.0",
      round: {
        id: 32,
        name: "Commanders",
        adp: null,
        index: 11,
        logo: "https://api.nfldraftfanatics.com/media/wsh_5TI9IsB.png",
      },
      player: {
        id: 3639,
        index: 39,
        name: "Javon Bullard",
        team_name: "Georgia",
        school: "Georgia",
        position: "S",
        value: 162,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3921,
      pick: 8,
      index: 40,
      tm: "Los_Angeles Chargers",
      created_at: "2023-10-30T13:13:44.344325Z",
      updated_at: "2023-10-30T13:13:44.344385Z",
      round_index_number: "2",
      round_index: "round 2",
      switching: "nan",
      city: "Los_Angeles",
      value: "149.0",
      round: {
        id: 22,
        name: "Chargers",
        adp: null,
        index: 23,
        logo: "https://api.nfldraftfanatics.com/media/lac.png",
      },
      player: {
        id: 3640,
        index: 40,
        name: "Andrew Mukuba",
        team_name: "Clemson",
        school: "Clemson",
        position: "S",
        value: 161,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3922,
      pick: 9,
      index: 41,
      tm: "Los_Angeles Rams",
      created_at: "2023-10-30T13:13:44.354746Z",
      updated_at: "2023-10-30T13:13:44.354808Z",
      round_index_number: "2",
      round_index: "round 2",
      switching: "nan",
      city: "Los_Angeles",
      value: "146.0",
      round: {
        id: 27,
        name: "Rams",
        adp: null,
        index: 28,
        logo: "https://api.nfldraftfanatics.com/media/lar.png",
      },
      player: {
        id: 3641,
        index: 41,
        name: "Blake Corum",
        team_name: "Michigan",
        school: "Michigan",
        position: "RB",
        value: 160,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3923,
      pick: 10,
      index: 42,
      tm: "Washington Commanders",
      created_at: "2023-10-30T13:13:44.363026Z",
      updated_at: "2023-10-30T13:13:44.363092Z",
      round_index_number: "2",
      round_index: "round 2",
      switching: "nan",
      city: "Washington",
      value: "142.0",
      round: {
        id: 32,
        name: "Commanders",
        adp: null,
        index: 11,
        logo: "https://api.nfldraftfanatics.com/media/wsh_5TI9IsB.png",
      },
      player: {
        id: 3642,
        index: 42,
        name: "Kingsley Suamataia",
        team_name: "BYU",
        school: "BYU",
        position: "OT",
        value: 159,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3924,
      pick: 11,
      index: 43,
      tm: "Indianapolis Colts",
      created_at: "2023-10-30T13:13:44.381453Z",
      updated_at: "2023-10-30T13:13:44.381507Z",
      round_index_number: "2",
      round_index: "round 2",
      switching: "nan",
      city: "Indianapolis",
      value: "138.0",
      round: {
        id: 24,
        name: "Colts",
        adp: null,
        index: 25,
        logo: "https://api.nfldraftfanatics.com/media/ind.png",
      },
      player: {
        id: 3643,
        index: 43,
        name: "Tyleik Williams",
        team_name: "Ohio State",
        school: "Ohio State",
        position: "DL",
        value: 158,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3925,
      pick: 12,
      index: 44,
      tm: "Houston Texans",
      created_at: "2023-10-30T13:13:44.396925Z",
      updated_at: "2023-10-30T13:13:44.396978Z",
      round_index_number: "2",
      round_index: "round 2",
      switching: "nan",
      city: "Houston",
      value: "135.0",
      round: {
        id: 2,
        name: "Texans",
        adp: null,
        index: 2,
        logo: "https://api.nfldraftfanatics.com/media/hou.png",
      },
      player: {
        id: 3644,
        index: 44,
        name: "Kris Jenkins",
        team_name: "Michigan",
        school: "Michigan",
        position: "DL",
        value: 157,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3926,
      pick: 13,
      index: 45,
      tm: "Tampa_Bay Buccaneers",
      created_at: "2023-10-30T13:13:44.407916Z",
      updated_at: "2023-10-30T13:13:44.407973Z",
      round_index_number: "2",
      round_index: "round 2",
      switching: "nan",
      city: "Tampa_Bay",
      value: "131.0",
      round: {
        id: 30,
        name: "Buccaneers",
        adp: 15,
        index: 31,
        logo: "https://api.nfldraftfanatics.com/media/tb.png",
      },
      player: {
        id: 3645,
        index: 45,
        name: "Keon Coleman",
        team_name: "Florida State",
        school: "Florida State",
        position: "WR",
        value: 156,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3927,
      pick: 14,
      index: 46,
      tm: "Tennessee Titans",
      created_at: "2023-10-30T13:13:44.418186Z",
      updated_at: "2023-10-30T13:13:44.418247Z",
      round_index_number: "2",
      round_index: "round 2",
      switching: "nan",
      city: "Tennessee",
      value: "128.0",
      round: {
        id: 16,
        name: "Titans",
        adp: null,
        index: 17,
        logo: "https://api.nfldraftfanatics.com/media/ten.png",
      },
      player: {
        id: 3646,
        index: 46,
        name: "Bo Nix",
        team_name: "Oregon",
        school: "Oregon",
        position: "QB",
        value: 155,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3928,
      pick: 15,
      index: 47,
      tm: "Las_Vegas Raiders",
      created_at: "2023-10-30T13:13:44.441634Z",
      updated_at: "2023-10-30T13:13:44.441694Z",
      round_index_number: "2",
      round_index: "round 2",
      switching: "nan",
      city: "Las_Vegas",
      value: "124.0",
      round: {
        id: 14,
        name: "Raiders",
        adp: null,
        index: 15,
        logo: "https://api.nfldraftfanatics.com/media/lv.png",
      },
      player: {
        id: 3647,
        index: 47,
        name: "Sedrick Van Pran",
        team_name: "Georgia",
        school: "Georgia",
        position: "C",
        value: 154,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3929,
      pick: 16,
      index: 48,
      tm: "Cincinnati Bengals",
      created_at: "2023-10-30T13:13:44.450955Z",
      updated_at: "2023-10-30T13:13:44.451020Z",
      round_index_number: "2",
      round_index: "round 2",
      switching: "nan",
      city: "Cincinnati",
      value: "121.0",
      round: {
        id: 26,
        name: "Bengals",
        adp: null,
        index: 27,
        logo: "https://api.nfldraftfanatics.com/media/cin.png",
      },
      player: {
        id: 3648,
        index: 48,
        name: "Troy Fautanu",
        team_name: "Washington",
        school: "Washington",
        position: "G",
        value: 153,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3930,
      pick: 17,
      index: 49,
      tm: "Philadelphia Eagles",
      created_at: "2023-10-30T13:13:44.459207Z",
      updated_at: "2023-10-30T13:13:44.459268Z",
      round_index_number: "2",
      round_index: "round 2",
      switching: "nan",
      city: "Philadelphia",
      value: "118.0",
      round: {
        id: 18,
        name: "Eagles",
        adp: null,
        index: 19,
        logo: "https://api.nfldraftfanatics.com/media/phi.png",
      },
      player: {
        id: 3649,
        index: 49,
        name: "Jack Sawyer",
        team_name: "Ohio State",
        school: "Ohio State",
        position: "EDGE",
        value: 152,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3931,
      pick: 18,
      index: 50,
      tm: "Green_Bay Packers",
      created_at: "2023-10-30T13:13:44.474106Z",
      updated_at: "2023-10-30T13:13:44.474160Z",
      round_index_number: "2",
      round_index: "round 2",
      switching: "nan",
      city: "Green_Bay",
      value: "115.0",
      round: {
        id: 28,
        name: "Packers",
        adp: null,
        index: 29,
        logo: "https://api.nfldraftfanatics.com/media/gb_1.png",
      },
      player: {
        id: 3650,
        index: 50,
        name: "Troy Franklin",
        team_name: "Oregon",
        school: "Oregon",
        position: "WR",
        value: 151,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3932,
      pick: 19,
      index: 51,
      tm: "Minnesota Vikings",
      created_at: "2023-10-30T13:13:44.484148Z",
      updated_at: "2023-10-30T13:13:44.484228Z",
      round_index_number: "2",
      round_index: "round 2",
      switching: "nan",
      city: "Minnesota",
      value: "112.0",
      round: {
        id: 12,
        name: "Vikings",
        adp: null,
        index: 13,
        logo: "https://api.nfldraftfanatics.com/media/min.png",
      },
      player: {
        id: 3651,
        index: 51,
        name: "Barrett Carter",
        team_name: "Clemson",
        school: "Clemson",
        position: "LB",
        value: 150,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3933,
      pick: 20,
      index: 52,
      tm: "Atlanta Falcons",
      created_at: "2023-10-30T13:13:44.493906Z",
      updated_at: "2023-10-30T13:13:44.493970Z",
      round_index_number: "2",
      round_index: "round 2",
      switching: "nan",
      city: "Atlanta",
      value: "109.0",
      round: {
        id: 1,
        name: "Falcons",
        adp: null,
        index: 1,
        logo: "https://api.nfldraftfanatics.com/media/atl_1.png",
      },
      player: {
        id: 3652,
        index: 52,
        name: "Jason Marshall Jr.",
        team_name: "Florida",
        school: "Florida",
        position: "CB",
        value: 149,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3934,
      pick: 21,
      index: 53,
      tm: "Pittsburgh Steelers",
      created_at: "2023-10-30T13:13:44.521212Z",
      updated_at: "2023-10-30T13:13:44.521270Z",
      round_index_number: "2",
      round_index: "round 2",
      switching: "nan",
      city: "Pittsburgh",
      value: "106.0",
      round: {
        id: 11,
        name: "Steelers",
        adp: null,
        index: 12,
        logo: "https://api.nfldraftfanatics.com/media/pit.png",
      },
      player: {
        id: 3653,
        index: 53,
        name: "Michael Penix Jr.",
        team_name: "Washington",
        school: "Washington",
        position: "QB",
        value: 148,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3935,
      pick: 22,
      index: 54,
      tm: "Buffalo Bills",
      created_at: "2023-10-30T13:13:44.536026Z",
      updated_at: "2023-10-30T13:13:44.536086Z",
      round_index_number: "2",
      round_index: "round 2",
      switching: "nan",
      city: "Buffalo",
      value: "104.0",
      round: {
        id: 31,
        name: "Bills",
        adp: null,
        index: 32,
        logo: "https://api.nfldraftfanatics.com/media/buf.png",
      },
      player: {
        id: 3654,
        index: 54,
        name: "Jordan Morgan",
        team_name: "Arizona",
        school: "Arizona",
        position: "OT",
        value: 147,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3936,
      pick: 23,
      index: 55,
      tm: "Cleveland Browns",
      created_at: "2023-10-30T13:13:44.545196Z",
      updated_at: "2023-10-30T13:13:44.545254Z",
      round_index_number: "2",
      round_index: "round 2",
      switching: "nan",
      city: "Cleveland",
      value: "101.0",
      round: {
        id: 17,
        name: "Browns",
        adp: null,
        index: 18,
        logo: "https://api.nfldraftfanatics.com/media/cle.png",
      },
      player: {
        id: 3655,
        index: 55,
        name: "Trey Benson",
        team_name: "Florida State",
        school: "Florida State",
        position: "RB",
        value: 146,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3937,
      pick: 24,
      index: 56,
      tm: "Seattle Seahawks",
      created_at: "2023-10-30T13:13:44.564773Z",
      updated_at: "2023-10-30T13:13:44.564834Z",
      round_index_number: "2",
      round_index: "round 2",
      switching: "nan",
      city: "Seattle",
      value: "98.0",
      round: {
        id: 4,
        name: "Seahawks",
        adp: null,
        index: 4,
        logo: "https://api.nfldraftfanatics.com/media/sea.png",
      },
      player: {
        id: 3656,
        index: 56,
        name: "J.J. McCarthy",
        team_name: "Michigan",
        school: "Michigan",
        position: "QB",
        value: 145,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3938,
      pick: 25,
      index: 57,
      tm: "San_Francisco 49ers",
      created_at: "2023-10-30T13:13:44.582599Z",
      updated_at: "2023-10-30T13:13:44.582647Z",
      round_index_number: "2",
      round_index: "round 2",
      switching: "nan",
      city: "San_Francisco",
      value: "96.0",
      round: {
        id: 23,
        name: "49ers",
        adp: null,
        index: 24,
        logo: "https://api.nfldraftfanatics.com/media/sf.png",
      },
      player: {
        id: 3657,
        index: 57,
        name: "Jaheim Bell",
        team_name: "Florida State",
        school: "Florida State",
        position: "TE",
        value: 144,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3939,
      pick: 26,
      index: 58,
      tm: "Baltimore Ravens",
      created_at: "2023-10-30T13:13:44.593775Z",
      updated_at: "2023-10-30T13:13:44.593833Z",
      round_index_number: "2",
      round_index: "round 2",
      switching: "nan",
      city: "Baltimore",
      value: "93.0",
      round: {
        id: 19,
        name: "Ravens",
        adp: null,
        index: 20,
        logo: "https://api.nfldraftfanatics.com/media/bal.png",
      },
      player: {
        id: 3658,
        index: 58,
        name: "Johnny Wilson",
        team_name: "Florida State",
        school: "Florida State",
        position: "WR",
        value: 143,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3940,
      pick: 27,
      index: 59,
      tm: "Dallas Cowboys",
      created_at: "2023-10-30T13:13:44.610688Z",
      updated_at: "2023-10-30T13:13:44.610750Z",
      round_index_number: "2",
      round_index: "round 2",
      switching: "nan",
      city: "Dallas",
      value: "91.0",
      round: {
        id: 25,
        name: "Cowboys",
        adp: null,
        index: 26,
        logo: "https://api.nfldraftfanatics.com/media/dal.png",
      },
      player: {
        id: 3659,
        index: 59,
        name: "Will Shipley",
        team_name: "Clemson",
        school: "Clemson",
        position: "RB",
        value: 142,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3941,
      pick: 28,
      index: 60,
      tm: "Jacksonville Jaguars",
      created_at: "2023-10-30T13:13:44.619945Z",
      updated_at: "2023-10-30T13:13:44.619993Z",
      round_index_number: "2",
      round_index: "round 2",
      switching: "nan",
      city: "Jacksonville",
      value: "88.0",
      round: {
        id: 6,
        name: "Jaguars",
        adp: null,
        index: 6,
        logo: "https://api.nfldraftfanatics.com/media/jax.png",
      },
      player: {
        id: 3660,
        index: 60,
        name: "Jalen McMillan",
        team_name: "Washington",
        school: "Washington",
        position: "WR",
        value: 141,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3942,
      pick: 29,
      index: 61,
      tm: "Detroit Lions",
      created_at: "2023-10-30T13:13:44.635383Z",
      updated_at: "2023-10-30T13:13:44.635443Z",
      round_index_number: "2",
      round_index: "round 2",
      switching: "nan",
      city: "Detroit",
      value: "86.0",
      round: {
        id: 3,
        name: "Lions",
        adp: null,
        index: 3,
        logo: "https://api.nfldraftfanatics.com/media/det.png",
      },
      player: {
        id: 3661,
        index: 61,
        name: "Cole Bishop",
        team_name: "Utah",
        school: "Utah",
        position: "S",
        value: 140,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3943,
      pick: 30,
      index: 62,
      tm: "Miami Dolphins",
      created_at: "2023-10-30T13:13:44.649637Z",
      updated_at: "2023-10-30T13:13:44.649704Z",
      round_index_number: "2",
      round_index: "round 2",
      switching: "nan",
      city: "Miami",
      value: "84.0",
      round: {
        id: 33,
        name: "Dolphins",
        adp: 12,
        index: 10,
        logo: "https://api.nfldraftfanatics.com/media/MIA.png",
      },
      player: {
        id: 3662,
        index: 62,
        name: "Connor Colby",
        team_name: "Iowa",
        school: "Iowa",
        position: "G",
        value: 139,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3944,
      pick: 31,
      index: 63,
      tm: "Kansas_City Chiefs",
      created_at: "2023-10-30T13:13:44.664019Z",
      updated_at: "2023-10-30T13:13:44.664075Z",
      round_index_number: "2",
      round_index: "round 2",
      switching: "nan",
      city: "Kansas_City",
      value: "82.0",
      round: {
        id: 29,
        name: "Chiefs",
        adp: null,
        index: 30,
        logo: "https://api.nfldraftfanatics.com/media/kc.png",
      },
      player: {
        id: 3663,
        index: 63,
        name: "Patrick Paul",
        team_name: "Houston",
        school: "Houston",
        position: "OT",
        value: 138,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3945,
      pick: 32,
      index: 64,
      tm: "Philadelphia Eagles",
      created_at: "2023-10-30T13:13:44.673422Z",
      updated_at: "2023-10-30T13:13:44.673478Z",
      round_index_number: "2",
      round_index: "round 2",
      switching: "nan",
      city: "Philadelphia",
      value: "80.0",
      round: {
        id: 18,
        name: "Eagles",
        adp: null,
        index: 19,
        logo: "https://api.nfldraftfanatics.com/media/phi.png",
      },
      player: {
        id: 3664,
        index: 64,
        name: "Braelon Allen",
        team_name: "Wisconsin",
        school: "Wisconsin",
        position: "RB",
        value: 137,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3946,
      pick: 1,
      index: 65,
      tm: "Carolina Panthers",
      created_at: "2023-10-30T13:13:44.684022Z",
      updated_at: "2023-10-30T13:13:44.684080Z",
      round_index_number: "3",
      round_index: "round 3",
      switching: "nan",
      city: "Carolina",
      value: "78.0",
      round: {
        id: 7,
        name: "Panthers",
        adp: null,
        index: 7,
        logo: "https://api.nfldraftfanatics.com/media/car.png",
      },
      player: {
        id: 3665,
        index: 65,
        name: "Terrion Arnold",
        team_name: "Alabama",
        school: "Alabama",
        position: "CB",
        value: 136,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3947,
      pick: 2,
      index: 66,
      tm: "Arizona Cardinals",
      created_at: "2023-10-30T13:13:44.702748Z",
      updated_at: "2023-10-30T13:13:44.702809Z",
      round_index_number: "3",
      round_index: "round 3",
      switching: "nan",
      city: "Arizona",
      value: "76.0",
      round: {
        id: 20,
        name: "Cardinals",
        adp: null,
        index: 21,
        logo: "https://api.nfldraftfanatics.com/media/ari.png",
      },
      player: {
        id: 3666,
        index: 66,
        name: "Kris Abrams-Draine",
        team_name: "Missouri",
        school: "Missouri",
        position: "CB",
        value: 135,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3948,
      pick: 3,
      index: 67,
      tm: "New_York Giants",
      created_at: "2023-10-30T13:13:44.711153Z",
      updated_at: "2023-10-30T13:13:44.711209Z",
      round_index_number: "3",
      round_index: "round 3",
      switching: "nan",
      city: "New_York",
      value: "75.0",
      round: {
        id: 9,
        name: "Giants",
        adp: null,
        index: 9,
        logo: "https://api.nfldraftfanatics.com/media/nyg.png",
      },
      player: {
        id: 3667,
        index: 67,
        name: "Nazir Stackhouse",
        team_name: "Georgia",
        school: "Georgia",
        position: "DL",
        value: 134,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3949,
      pick: 4,
      index: 68,
      tm: "New_England Patriots",
      created_at: "2023-10-30T13:13:44.728515Z",
      updated_at: "2023-10-30T13:13:44.728647Z",
      round_index_number: "3",
      round_index: "round 3",
      switching: "nan",
      city: "New_England",
      value: "73.0",
      round: {
        id: 15,
        name: "Patriots",
        adp: null,
        index: 16,
        logo: "https://api.nfldraftfanatics.com/media/ne.png",
      },
      player: {
        id: 3668,
        index: 68,
        name: "Christian Mahogany",
        team_name: "Boston College",
        school: "Boston College",
        position: "G",
        value: 133,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3950,
      pick: 5,
      index: 69,
      tm: "Denver Broncos",
      created_at: "2023-10-30T13:13:44.741329Z",
      updated_at: "2023-10-30T13:13:44.741391Z",
      round_index_number: "3",
      round_index: "round 3",
      switching: "nan",
      city: "Denver",
      value: "71.0",
      round: {
        id: 21,
        name: "Broncos",
        adp: null,
        index: 22,
        logo: "https://api.nfldraftfanatics.com/media/den.png",
      },
      player: {
        id: 3669,
        index: 69,
        name: "Javon Foster",
        team_name: "Missouri",
        school: "Missouri",
        position: "OT",
        value: 132,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3951,
      pick: 6,
      index: 70,
      tm: "Chicago Bears",
      created_at: "2023-10-30T13:13:44.770343Z",
      updated_at: "2023-10-30T13:13:44.770396Z",
      round_index_number: "3",
      round_index: "round 3",
      switching: "nan",
      city: "Chicago",
      value: "70.0",
      round: {
        id: 8,
        name: "Bears",
        adp: 1,
        index: 8,
        logo: "https://api.nfldraftfanatics.com/media/chi.png",
      },
      player: {
        id: 3670,
        index: 70,
        name: "Tommy Eichenberg",
        team_name: "Ohio State",
        school: "Ohio State",
        position: "LB",
        value: 131,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3952,
      pick: 7,
      index: 71,
      tm: "Green_Bay Packers",
      created_at: "2023-10-30T13:13:44.779615Z",
      updated_at: "2023-10-30T13:13:44.779672Z",
      round_index_number: "3",
      round_index: "round 3",
      switching: "nan",
      city: "Green_Bay",
      value: "68.0",
      round: {
        id: 28,
        name: "Packers",
        adp: null,
        index: 29,
        logo: "https://api.nfldraftfanatics.com/media/gb_1.png",
      },
      player: {
        id: 3671,
        index: 71,
        name: "Oronde Gadsden II",
        team_name: "Syracuse",
        school: "Syracuse",
        position: "WR",
        value: 130,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3953,
      pick: 8,
      index: 72,
      tm: "Los_Angeles Chargers",
      created_at: "2023-10-30T13:13:44.803789Z",
      updated_at: "2023-10-30T13:13:44.803848Z",
      round_index_number: "3",
      round_index: "round 3",
      switching: "nan",
      city: "Los_Angeles",
      value: "67.0",
      round: {
        id: 22,
        name: "Chargers",
        adp: null,
        index: 23,
        logo: "https://api.nfldraftfanatics.com/media/lac.png",
      },
      player: {
        id: 3672,
        index: 72,
        name: "Tyler Davis",
        team_name: "Clemson",
        school: "Clemson",
        position: "DL",
        value: 129,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3954,
      pick: 9,
      index: 73,
      tm: "Washington Commanders",
      created_at: "2023-10-30T13:13:44.814971Z",
      updated_at: "2023-10-30T13:13:44.815019Z",
      round_index_number: "3",
      round_index: "round 3",
      switching: "nan",
      city: "Washington",
      value: "65.0",
      round: {
        id: 32,
        name: "Commanders",
        adp: null,
        index: 11,
        logo: "https://api.nfldraftfanatics.com/media/wsh_5TI9IsB.png",
      },
      player: {
        id: 3673,
        index: 73,
        name: "Riley Leonard",
        team_name: "Duke",
        school: "Duke",
        position: "QB",
        value: 128,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3955,
      pick: 10,
      index: 74,
      tm: "Indianapolis Colts",
      created_at: "2023-10-30T13:13:44.842834Z",
      updated_at: "2023-10-30T13:13:44.842896Z",
      round_index_number: "3",
      round_index: "round 3",
      switching: "nan",
      city: "Indianapolis",
      value: "64.0",
      round: {
        id: 24,
        name: "Colts",
        adp: null,
        index: 25,
        logo: "https://api.nfldraftfanatics.com/media/ind.png",
      },
      player: {
        id: 3674,
        index: 74,
        name: "Jimmy Horn Jr.",
        team_name: "Colorado",
        school: "Colorado",
        position: "WR",
        value: 127,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3956,
      pick: 11,
      index: 75,
      tm: "Los_Angelas Rams",
      created_at: "2023-10-30T13:13:44.851690Z",
      updated_at: "2023-10-30T13:13:44.851737Z",
      round_index_number: "3",
      round_index: "round 3",
      switching: "nan",
      city: "Los_Angelas",
      value: "63.0",
      round: {
        id: 27,
        name: "Rams",
        adp: null,
        index: 28,
        logo: "https://api.nfldraftfanatics.com/media/lar.png",
      },
      player: {
        id: 3675,
        index: 75,
        name: "T'Vondre Sweat",
        team_name: "Texas",
        school: "Texas",
        position: "DL",
        value: 126,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3957,
      pick: 12,
      index: 76,
      tm: "Tampa_Bay Buccaneers",
      created_at: "2023-10-30T13:13:44.861985Z",
      updated_at: "2023-10-30T13:13:44.862049Z",
      round_index_number: "3",
      round_index: "round 3",
      switching: "nan",
      city: "Tampa_Bay",
      value: "61.0",
      round: {
        id: 30,
        name: "Buccaneers",
        adp: 15,
        index: 31,
        logo: "https://api.nfldraftfanatics.com/media/tb.png",
      },
      player: {
        id: 3676,
        index: 76,
        name: "Adonai Mitchell",
        team_name: "Texas",
        school: "Texas",
        position: "WR",
        value: 125,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3958,
      pick: 13,
      index: 77,
      tm: "Arizona Cardinals",
      created_at: "2023-10-30T13:13:44.877823Z",
      updated_at: "2023-10-30T13:13:44.877876Z",
      round_index_number: "3",
      round_index: "round 3",
      switching: "nan",
      city: "Arizona",
      value: "60.0",
      round: {
        id: 20,
        name: "Cardinals",
        adp: null,
        index: 21,
        logo: "https://api.nfldraftfanatics.com/media/ari.png",
      },
      player: {
        id: 3677,
        index: 77,
        name: "Chris Braswell",
        team_name: "Alabama",
        school: "Alabama",
        position: "EDGE",
        value: 124,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3959,
      pick: 14,
      index: 78,
      tm: "Las_Vegas Raiders",
      created_at: "2023-10-30T13:13:44.886537Z",
      updated_at: "2023-10-30T13:13:44.886596Z",
      round_index_number: "3",
      round_index: "round 3",
      switching: "nan",
      city: "Las_Vegas",
      value: "59.0",
      round: {
        id: 14,
        name: "Raiders",
        adp: null,
        index: 15,
        logo: "https://api.nfldraftfanatics.com/media/lv.png",
      },
      player: {
        id: 3678,
        index: 78,
        name: "Zak Zinter",
        team_name: "Michigan",
        school: "Michigan",
        position: "G",
        value: 123,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3960,
      pick: 15,
      index: 79,
      tm: "Arizona Cardinals",
      created_at: "2023-10-30T13:13:44.930811Z",
      updated_at: "2023-10-30T13:13:44.930870Z",
      round_index_number: "3",
      round_index: "round 3",
      switching: "nan",
      city: "Arizona",
      value: "57.0",
      round: {
        id: 20,
        name: "Cardinals",
        adp: null,
        index: 21,
        logo: "https://api.nfldraftfanatics.com/media/ari.png",
      },
      player: {
        id: 3679,
        index: 79,
        name: "Blake Fisher",
        team_name: "Notre Dame",
        school: "Notre Dame",
        position: "OT",
        value: 122,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3961,
      pick: 16,
      index: 80,
      tm: "Seattle Seahawks",
      created_at: "2023-10-30T13:13:44.940461Z",
      updated_at: "2023-10-30T13:13:44.940661Z",
      round_index_number: "3",
      round_index: "round 3",
      switching: "nan",
      city: "Seattle",
      value: "56.0",
      round: {
        id: 4,
        name: "Seahawks",
        adp: null,
        index: 4,
        logo: "https://api.nfldraftfanatics.com/media/sea.png",
      },
      player: {
        id: 3680,
        index: 80,
        name: "Malachi Moore",
        team_name: "Alabama",
        school: "Alabama",
        position: "S",
        value: 121,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3962,
      pick: 17,
      index: 81,
      tm: "Cincinnati Bengals",
      created_at: "2023-10-30T13:13:44.952683Z",
      updated_at: "2023-10-30T13:13:44.952742Z",
      round_index_number: "3",
      round_index: "round 3",
      switching: "nan",
      city: "Cincinnati",
      value: "55.0",
      round: {
        id: 26,
        name: "Bengals",
        adp: null,
        index: 27,
        logo: "https://api.nfldraftfanatics.com/media/cin.png",
      },
      player: {
        id: 3681,
        index: 81,
        name: "Ainias Smith",
        team_name: "Texas A&M",
        school: "Texas A&M",
        position: "WR",
        value: 120,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3963,
      pick: 18,
      index: 82,
      tm: "New_York Jets",
      created_at: "2023-10-30T13:13:44.963951Z",
      updated_at: "2023-10-30T13:13:44.964011Z",
      round_index_number: "3",
      round_index: "round 3",
      switching: "nan",
      city: "New_York",
      value: "54.0",
      round: {
        id: 5,
        name: "Jets",
        adp: null,
        index: 5,
        logo: "https://api.nfldraftfanatics.com/media/nyj.png",
      },
      player: {
        id: 3682,
        index: 82,
        name: "James Williams",
        team_name: "Miami (FL)",
        school: "Miami (FL)",
        position: "S",
        value: 119,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3964,
      pick: 19,
      index: 83,
      tm: "Atlanta Falcons",
      created_at: "2023-10-30T13:13:44.973820Z",
      updated_at: "2023-10-30T13:13:44.973881Z",
      round_index_number: "3",
      round_index: "round 3",
      switching: "nan",
      city: "Atlanta",
      value: "52.0",
      round: {
        id: 1,
        name: "Falcons",
        adp: null,
        index: 1,
        logo: "https://api.nfldraftfanatics.com/media/atl_1.png",
      },
      player: {
        id: 3683,
        index: 83,
        name: "Donovan Edwards",
        team_name: "Michigan",
        school: "Michigan",
        position: "RB",
        value: 118,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3965,
      pick: 20,
      index: 84,
      tm: "Detroit Lions",
      created_at: "2023-10-30T13:13:44.990489Z",
      updated_at: "2023-10-30T13:13:44.990555Z",
      round_index_number: "3",
      round_index: "round 3",
      switching: "nan",
      city: "Detroit",
      value: "51.0",
      round: {
        id: 3,
        name: "Lions",
        adp: null,
        index: 3,
        logo: "https://api.nfldraftfanatics.com/media/det.png",
      },
      player: {
        id: 3684,
        index: 84,
        name: "Jordan Travis",
        team_name: "Florida State",
        school: "Florida State",
        position: "QB",
        value: 117,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3966,
      pick: 21,
      index: 85,
      tm: "Pittsburgh Steelers",
      created_at: "2023-10-30T13:13:45.005083Z",
      updated_at: "2023-10-30T13:13:45.005139Z",
      round_index_number: "3",
      round_index: "round 3",
      switching: "nan",
      city: "Pittsburgh",
      value: "50.0",
      round: {
        id: 11,
        name: "Steelers",
        adp: null,
        index: 12,
        logo: "https://api.nfldraftfanatics.com/media/pit.png",
      },
      player: {
        id: 3685,
        index: 85,
        name: "Donovan Jackson",
        team_name: "Ohio State",
        school: "Ohio State",
        position: "G",
        value: 116,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3967,
      pick: 22,
      index: 86,
      tm: "Green Bay Packers",
      created_at: "2023-10-30T13:13:45.014324Z",
      updated_at: "2023-11-26T19:43:34.229416Z",
      round_index_number: "3",
      round_index: "round 3",
      switching: "nan",
      city: "Green Bay",
      value: "49.0",
      round: {
        id: 28,
        name: "Packers",
        adp: null,
        index: 29,
        logo: "https://api.nfldraftfanatics.com/media/gb_1.png",
      },
      player: {
        id: 3686,
        index: 86,
        name: "Josh Newton",
        team_name: "TCU",
        school: "TCU",
        position: "CB",
        value: 115,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3968,
      pick: 23,
      index: 87,
      tm: "Seattle Seahawks",
      created_at: "2023-10-30T13:13:45.031230Z",
      updated_at: "2023-10-30T13:13:45.031297Z",
      round_index_number: "3",
      round_index: "round 3",
      switching: "nan",
      city: "Seattle",
      value: "48.0",
      round: {
        id: 4,
        name: "Seahawks",
        adp: null,
        index: 4,
        logo: "https://api.nfldraftfanatics.com/media/sea.png",
      },
      player: {
        id: 3687,
        index: 87,
        name: "Shemar Turner",
        team_name: "Texas A&M",
        school: "Texas A&M",
        position: "DL",
        value: 114,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3969,
      pick: 24,
      index: 88,
      tm: "Cleveland Browns",
      created_at: "2023-10-30T13:13:45.041131Z",
      updated_at: "2023-10-30T13:13:45.041194Z",
      round_index_number: "3",
      round_index: "round 3",
      switching: "nan",
      city: "Cleveland",
      value: "47.0",
      round: {
        id: 17,
        name: "Browns",
        adp: null,
        index: 18,
        logo: "https://api.nfldraftfanatics.com/media/cle.png",
      },
      player: {
        id: 3688,
        index: 88,
        name: "D.J. James",
        team_name: "Auburn",
        school: "Auburn",
        position: "CB",
        value: 113,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3970,
      pick: 25,
      index: 89,
      tm: "Baltimore Ravens",
      created_at: "2023-10-30T13:13:45.050928Z",
      updated_at: "2023-10-30T13:13:45.050979Z",
      round_index_number: "3",
      round_index: "round 3",
      switching: "nan",
      city: "Baltimore",
      value: "46.0",
      round: {
        id: 19,
        name: "Ravens",
        adp: null,
        index: 20,
        logo: "https://api.nfldraftfanatics.com/media/bal.png",
      },
      player: {
        id: 3689,
        index: 89,
        name: "Tyler Guyton",
        team_name: "Oklahoma",
        school: "Oklahoma",
        position: "OT",
        value: 112,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3971,
      pick: 26,
      index: 90,
      tm: "Dallas Cowboys",
      created_at: "2023-10-30T13:13:45.060663Z",
      updated_at: "2023-10-30T13:13:45.060728Z",
      round_index_number: "3",
      round_index: "round 3",
      switching: "nan",
      city: "Dallas",
      value: "45.0",
      round: {
        id: 25,
        name: "Cowboys",
        adp: null,
        index: 26,
        logo: "https://api.nfldraftfanatics.com/media/dal.png",
      },
      player: {
        id: 3690,
        index: 90,
        name: "Jack Nelson",
        team_name: "Wisconsin",
        school: "Wisconsin",
        position: "OT",
        value: 111,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3972,
      pick: 27,
      index: 91,
      tm: "San_Francisco 49ers",
      created_at: "2023-10-30T13:13:45.070383Z",
      updated_at: "2023-10-30T13:13:45.070486Z",
      round_index_number: "3",
      round_index: "round 3",
      switching: "nan",
      city: "San_Francisco",
      value: "44.0",
      round: {
        id: 23,
        name: "49ers",
        adp: null,
        index: 24,
        logo: "https://api.nfldraftfanatics.com/media/sf.png",
      },
      player: {
        id: 3691,
        index: 91,
        name: "Brandon Dorlus",
        team_name: "Oregon",
        school: "Oregon",
        position: "DL",
        value: 110,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3973,
      pick: 28,
      index: 92,
      tm: "Jacksonville Jaguars",
      created_at: "2023-10-30T13:13:45.078492Z",
      updated_at: "2023-10-30T13:13:45.078539Z",
      round_index_number: "3",
      round_index: "round 3",
      switching: "nan",
      city: "Jacksonville",
      value: "43.0",
      round: {
        id: 6,
        name: "Jaguars",
        adp: null,
        index: 6,
        logo: "https://api.nfldraftfanatics.com/media/jax.png",
      },
      player: {
        id: 3692,
        index: 92,
        name: "Jayden Daniels",
        team_name: "LSU",
        school: "LSU",
        position: "QB",
        value: 109,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3974,
      pick: 29,
      index: 93,
      tm: "Detroit Lions",
      created_at: "2023-10-30T13:13:45.092811Z",
      updated_at: "2023-10-30T13:13:45.092871Z",
      round_index_number: "3",
      round_index: "round 3",
      switching: "nan",
      city: "Detroit",
      value: "42.0",
      round: {
        id: 3,
        name: "Lions",
        adp: null,
        index: 3,
        logo: "https://api.nfldraftfanatics.com/media/det.png",
      },
      player: {
        id: 3693,
        index: 93,
        name: "Princely Umanmielen",
        team_name: "Florida",
        school: "Florida",
        position: "EDGE",
        value: 108,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3975,
      pick: 30,
      index: 94,
      tm: "Kansas_City Chiefs",
      created_at: "2023-10-30T13:13:45.108511Z",
      updated_at: "2023-10-30T13:13:45.108587Z",
      round_index_number: "3",
      round_index: "round 3",
      switching: "nan",
      city: "Kansas_City",
      value: "41.0",
      round: {
        id: 29,
        name: "Chiefs",
        adp: null,
        index: 30,
        logo: "https://api.nfldraftfanatics.com/media/kc.png",
      },
      player: {
        id: 3694,
        index: 94,
        name: "Max Melton",
        team_name: "Rutgers",
        school: "Rutgers",
        position: "CB",
        value: 107,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3976,
      pick: 31,
      index: 95,
      tm: "Houston Texans",
      created_at: "2023-10-30T13:13:45.117941Z",
      updated_at: "2023-10-30T13:13:45.118001Z",
      round_index_number: "3",
      round_index: "round 3",
      switching: "nan",
      city: "Houston",
      value: "40.0",
      round: {
        id: 2,
        name: "Texans",
        adp: null,
        index: 2,
        logo: "https://api.nfldraftfanatics.com/media/hou.png",
      },
      player: {
        id: 3695,
        index: 95,
        name: "Tyler Nubin",
        team_name: "Minnesota",
        school: "Minnesota",
        position: "S",
        value: 106,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3977,
      pick: 32,
      index: 96,
      tm: "Jacksonville Jaguars",
      created_at: "2023-10-30T13:13:45.130342Z",
      updated_at: "2023-10-30T13:13:45.130401Z",
      round_index_number: "3",
      round_index: "round 3",
      switching: "nan",
      city: "Jacksonville",
      value: "39.0",
      round: {
        id: 6,
        name: "Jaguars",
        adp: null,
        index: 6,
        logo: "https://api.nfldraftfanatics.com/media/jax.png",
      },
      player: {
        id: 3696,
        index: 96,
        name: "Malachi Corley",
        team_name: "Western Kentucky",
        school: "Western Kentucky",
        position: "WR",
        value: 105,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
    {
      id: 3978,
      pick: 33,
      index: 97,
      tm: "Buffalo Bills",
      created_at: "2023-10-30T13:13:45.144211Z",
      updated_at: "2023-10-30T13:13:45.144260Z",
      round_index_number: "3",
      round_index: "round 3",
      switching: "nan",
      city: "Buffalo",
      value: "38.34",
      round: {
        id: 31,
        name: "Bills",
        adp: null,
        index: 32,
        logo: "https://api.nfldraftfanatics.com/media/buf.png",
      },
      player: {
        id: 3697,
        index: 97,
        name: "McCallan Castles",
        team_name: "Tennessee",
        school: "Tennessee",
        position: "TE",
        value: 104,
        event_id: 5,
        round_id: null,
        school_ref: null,
      },
    },
  ],
  myBoard: {
    myEventTeam: {
      round: {
        id: 8,
        name: 'Bears',
        adp: 1,
        index: 8,
        logo: 'https://api.nfldraftfanatics.com/media/chi.png'
      },
      id: 81,
      team_neads_info: [
        {
          id: 349,
          priority: 'P1',
          created_at: '2022-11-01T07:32:17.393682Z',
          updated_at: '2023-11-19T19:56:57.484368Z',
          team_neads: {
            id: 81,
            created_at: '2022-11-01T07:32:17.387730Z',
            updated_at: '2023-11-19T19:56:57.483008Z',
            round: 8
          },
          positions: [
            {
              id: 1,
              name: 'QB'
            }
          ]
        },
        {
          id: 350,
          priority: 'P3',
          created_at: '2022-11-01T07:32:17.411173Z',
          updated_at: '2023-11-19T19:56:57.489818Z',
          team_neads: {
            id: 81,
            created_at: '2022-11-01T07:32:17.387730Z',
            updated_at: '2023-11-19T19:56:57.483008Z',
            round: 8
          },
          positions: [
            {
              id: 2,
              name: 'WR'
            },
            {
              id: 9,
              name: 'DT'
            }
          ]
        },
        {
          id: 351,
          priority: 'P10',
          created_at: '2022-11-01T07:32:17.442188Z',
          updated_at: '2023-11-19T19:56:57.494690Z',
          team_neads: {
            id: 81,
            created_at: '2022-11-01T07:32:17.387730Z',
            updated_at: '2023-11-19T19:56:57.483008Z',
            round: 8
          },
          positions: [
            {
              id: 2,
              name: 'WR'
            },
            {
              id: 5,
              name: 'OT'
            },
            {
              id: 7,
              name: 'CB'
            },
            {
              id: 8,
              name: 'S'
            },
            {
              id: 9,
              name: 'DT'
            },
            {
              id: 10,
              name: 'EDGE'
            },
            {
              id: 11,
              name: 'LB'
            },
            {
              id: 12,
              name: 'C'
            }
          ]
        }
      ]
    },
    myPlayerTeam: [
      {
        id: 4801,
        index: 1,
        name: 'Caleb Williams',
        team_name: 'USC',
        school: 'USC',
        position: 'QB',
        value: 200,
        event_id: 8,
        round_id: null,
        school_ref: null,
        pick: 1,
        round_index_number: '1'
      },
      {
        id: 4804,
        index: 4,
        name: 'Joe Alt',
        team_name: 'Notre Dame',
        school: 'Notre Dame',
        position: 'OT',
        value: 197,
        event_id: 8,
        round_id: null,
        school_ref: null,
        pick: 4,
        round_index_number: '1'
      }
    ]
  },
  round: 7,
};

const liveResultSlice = createSlice({
  name: "liveResult",
  initialState,
  reducers: {
    setLiveResultBoard: (state, { payload }) => {
      state.resultBoard = payload.resultBoard;
      state.myBoard = payload.myBoard;
      state.round = payload.round;
    },
    resetLiveResult: () => initialState,
  },
});

export const { setLiveResultBoard, resetLiveResult } = liveResultSlice.actions;

export const selectLiveResult = (state) => state.liveResult;

export default liveResultSlice.reducer
