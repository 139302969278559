import Router from "./router/router";
import { ScrollToTop } from "./scrollTop";
function App() {

  return (
    <>
      <ScrollToTop />
      <Router />
    </>
  );
}

export default App;
